<!--
* @Description 解决方案-倾斜摄影
* @fileName index.vue
* @MethodAuthor stjh
* @Date 2023-06-30 17:20:54
-->
<template>
  <div class="aboutContainer">
    <div class="top"></div>
    <div class="con">
      <h1 class="jjfaTitle">{{ $t("photo.section1_title") }}</h1>
      <div class="conImgH674 mb32">
        <!-- 视频 -->
        <video controls style="width: 100%">
          <source
            :src="
              this.$videBaseUrlNew +
              '2024-03-21/2c6b3171-820c-40a7-b256-7ed1b3c4f153.mp4'
            "
            type="video/mp4"
          />
        </video>
      </div>
      <div class="conImgH674 mb32">
        <!-- 视频 -->
        <video controls style="width: 100%">
          <source
            :src="
              this.$videBaseUrlNew +
              '2024-03-21/b2baf888-a746-4815-89fd-75364103535f.mp4'
            "
            type="video/mp4"
          />
        </video>
      </div>
      <div class="conImgH674 mb32">
        <!-- 视频 -->
        <video controls style="width: 100%">
          <source
            :src="
              this.$videBaseUrlNew +
              '2024-03-21/30dce039-1852-4203-85cc-cb44da5e9afc.mp4'
            "
            type="video/mp4"
          />
        </video>
      </div>
      <div class="conText mb32">
        <p style="text-indent: 34px">{{ $t("photo.section1_content1") }}</p>
        <br />
        <p style="text-indent: 34px">{{ $t("photo.section1_content2") }}</p>
      </div>
      <div class="conImgH534 pb32">
        <img :src="imgs[1].url" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgs: [
        {
          url: require("../../../assets/images/programmeImg/wt@3x.png"),
        },
        { url: require("../../../assets/images/programmeImg/Qxsy1.png") },
      ],
      publicPath: process.env.BASE_URL,
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.aboutContainer {
  background: #ffffff;
  padding-bottom: 80px;
  .top {
    width: 100%;
    height: 640px;
    // background: #b6bfd6;
    background-image: url("~@/assets/images/programmeImg/QxsyBanner@3x.png");
    background-size: 100% 640px;
    background-repeat: no-repeat;
  }

  .con {
    width: 1200px;
    text-align: center;
    // padding: 0 360px;
    margin: 0 auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
